import { gql } from '@apollo/client';
import ActionButton from 'components/ActionButton';
import { ENUMS } from 'components/Enum/enums';
import ImpersonateButton from 'components/ImpersonateButton';
import StripeAccountsTab from 'components/StripeAccountsTab';
import DisputesTab from 'components/tabs/DisputesTab';
import NotesTab from 'components/tabs/NotesTab';
import getRelationshipsTab from 'components/tabs/RelationshipsTab';
import WageLogsTab from 'components/tabs/WageLogsTab';
import useCheckAccess from 'hooks/useCheckAccess';
import * as React from 'react';
import {
  EditButton,
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TopToolbar,
  useGetOne,
  useGetRecordId,
  useRecordContext,
} from 'react-admin';
import CapsReportVerify from 'resources/caps_reports/CapsReportVerify';
import ApplicationStatusControl from './ApplicationStatusControl';
import { ExportProfile } from './ExportProfile';
import { ItemField } from './ItemField';
import { ProfessionalAside } from './ProfessionalAside';
import ProfessionalEvents from './ProfessionalEvents';
import { ProfessionalTitle } from './ProfessionalTitle';
import UniversalBackground from './UniversalBackground';
import UploadItem from './UploadItem';
import ApplicationTab from './show_tabs/ApplicationTab';
import AssignmentFavoritesTab from './show_tabs/AssignmentFavoritesTab';
import AssignmentRequestsTab from './show_tabs/AssignmentRequestsTab';
import AssignmentsTab from './show_tabs/AssignmentsTab';
import CredentialsTab from './show_tabs/CredentialsTab';
import JobsTab from './show_tabs/JobsTab';
import PayoutsTab from './show_tabs/PayoutsTab';
import PositionsTab from './show_tabs/PositionsTab';
import ProfileTab from './show_tabs/ProfileTab';
import ResumeTab from './show_tabs/ResumeTab';
import ReviewsTab from './show_tabs/ReviewsTab';
import SkillsTab from './show_tabs/SkillsTab';
import SpecialtiesTab from './show_tabs/SpecialtiesTab';
import TravelPreferencesTab from './show_tabs/TravelPreferencesTab';

const RelationshipsTab = getRelationshipsTab('Professional', 'Location');

const {
  Professional: { status },
} = ENUMS;

const ProfessionalActions = () => {
  const record = useRecordContext();
  const checkAccess = useCheckAccess();
  if (!record) {
    return null;
  }
  const isNursysAvailable = [
    status.application,
    status.active,
    status.limited_access_reinstatement,
    status.limited_access,
    status.paused,
    status.paused_requirement_submitted,
  ].includes(record.status);

  return (
    <TopToolbar sx={{ flexWrap: 'wrap', marginTop: { xs: 8, sm: 4, md: 0 } }}>
      {[status.active].includes(record.status) && <ExportProfile record={record} />}
      <UniversalBackground />
      <ApplicationStatusControl />
      <ItemField record={record} />
      <UploadItem record={record} />
      <ProfessionalEvents record={record} />
      {checkAccess('actions', 'Professional', 'caps') &&
        [status.active].includes(record.status) &&
        !record.capsReports && <CapsReportVerify professionalId={record.id} />}
      {isNursysAvailable && (
        <ActionButton
          text={record.in_nursys_list ? 'Submit Nursys Verification' : 'Add To Nursys List'}
          url={`/v3/admin/professionals/${record.id}/nursys_verification`}
          method="POST"
        />
      )}
      {record.can_enter_upload_complete && (
        <ActionButton
          text="Submit Background Check"
          url={`/v3/admin/professionals/${record.id}/submit_background_check`}
          method="PUT"
        />
      )}
      <EditButton data-testid="professional-edit_button" />
      <ImpersonateButton account={record.account} />
    </TopToolbar>
  );
};

const ProfessionalsShow = () => {
  const checkAccess = useCheckAccess();
  const id = useGetRecordId();
  const { data: record } = useGetOne('Professional', { id });
  const isApplicant = !['approved', 'rejected', 'archived', 'account_info'].includes(
    record?.application_status,
  );
  if (!record) {
    return null;
  }

  return (
    <Show
      actions={<ProfessionalActions />}
      aside={<ProfessionalAside />}
      title={<ProfessionalTitle />}
    >
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Summary" data-testid="professional-summary_tab">
          <ProfileTab />
        </Tab>
        <Tab label="Resume" path="resume" data-testid="professional-resume_tab">
          <ResumeTab />
        </Tab>
        <Tab label="Application" data-testid="professional-application_tab">
          <ApplicationTab />
        </Tab>
        {checkAccess('view', 'ProfessionalQualification') && (
          <Tab label="credentials" path="credentials" data-testid="professional-credentials_tab">
            <CredentialsTab />
          </Tab>
        )}
        {checkAccess('view', 'Position') && (
          <Tab label="Positions" path="positions" data-testid="professional-positions_tab">
            <PositionsTab
              canCreate={checkAccess('create', 'Position')}
              canDelete={checkAccess('delete', 'Position')}
            />
          </Tab>
        )}
        {checkAccess('view', 'Specialty') && (
          <Tab label="Specialties" path="specialties" data-testid="professional-specialties_tab">
            <SpecialtiesTab />
          </Tab>
        )}

        {!isApplicant && (
          <Tab label="jobs" path="jobs" data-testid="professional-jobs_tab">
            <JobsTab />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'Assignment') && (
          <Tab label="Assignments" path="assignments" data-testid="professional-assignments_tab">
            <AssignmentsTab />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'AssignmentRequest') && (
          <Tab
            label="assignment requests"
            path="assignment_requests"
            data-testid="professional-assignment_requests_tab"
          >
            <AssignmentRequestsTab />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'Assignment') && (
          <Tab
            label="Assignment Favorites"
            path="assignment_favorites"
            data-testid="professional-assignment_favorites_tab"
          >
            <AssignmentFavoritesTab />
          </Tab>
        )}
        {!isApplicant && (
          <Tab label="skills" path="skills" data-testid="professional-skills_tab">
            <SkillsTab />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'ProfessionalTravelAssignmentState') && (
          <Tab
            label="Travel Preferences"
            path="travel_preferences"
            data-testid="professional-travel_preferences_tab"
          >
            <TravelPreferencesTab />
          </Tab>
        )}
        {!isApplicant && (
          <Tab
            label="Stripe accounts"
            path="stripe_accounts"
            data-testid="professional-stripe_accounts_tab"
          >
            <StripeAccountsTab />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'Payout') && (
          <Tab label="Payouts" path="payouts" data-testid="professional-payouts_tab">
            <PayoutsTab />
          </Tab>
        )}
        {checkAccess('view', 'Dispute') && (
          <Tab label="Disputes" path="disputes">
            <DisputesTab target="professional_id" />
          </Tab>
        )}
        {!isApplicant && checkAccess('view', 'Relationship') && (
          <Tab
            label="Relationships"
            path="relationships"
            data-testid="professional-relationships_tab"
          >
            <RelationshipsTab canCreate canDelete />
          </Tab>
        )}
        {!isApplicant && (
          <Tab label="Reviews" path="reviews" data-testid="professional-reviews_tab">
            <ReviewsTab />
          </Tab>
        )}
        <Tab label="Notes" path="notes" data-testid="professional-notes_tab">
          <NotesTab />
        </Tab>
        <Tab label="Wage Logs" path="wage_logs" data-testid="professional-wage_logs_tab">
          <WageLogsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

ProfessionalsShow.query = gql`
  query GET_ONE($id: Int!) {
    professional(id: $id) {
      account {
        account_type
        address_city
        address_first
        address_second
        address_zip
        agency {
          name
        }
        agency_id
        company {
          name
        }
        company_id
        coordinates {
          latitude
          longitude
        }
        email
        email_notification
        first_name
        id
        last_name
        middle_name
        phone_number
        sms_notification
        ssn_last_4
        state {
          abbreviation
          id
        }
      }
      account_id
      application_status
      application_version
      archive_reason
      background_check_authorized
      background_check_pqs {
        id
        metadata
        status
      }
      balance_cents
      bank_last
      booked_total_cents
      calendly_invitee_uuid
      cancellation_ratio
      capsReports {
        id
        status
      }
      created_at
      deactivation_reason
      detailed_notes
      dob
      drug_screening_completed
      has_item
      has_item_url
      has_upload_pdf
      hours_need_updating
      id
      in_nursys_list
      invite_code
      kinds
      late_clockin_ratio
      markets {
        display_name
        id
      }
      migrated_lead_id
      nickname
      nursysEnrollments {
        id
        professional_error
        status
      }
      other_referral_source
      paylocity_external_id
      phone_interviewed
      phone_screen_date_and_time
      positions {
        display_name
        id
      }
      professional_positions {
        id
        position {
          display_name
          id
        }
        years_of_experience
      }
      profile_strength
      quick_note
      recruiter {
        account {
          id
          name
        }
        id
      }
      recruiter_id
      referral_source {
        id
        label
        name
      }
      referral_source_id
      reject_reason
      resume
      rfm
      rfmf
      rfmr
      satisfied_conditions {
        condition_type
        id
        label
        payload
      }
      screening_recruiter {
        account {
          id
          name
        }
        id
      }
      screening_recruiter_id
      signup_assignment_id
      specialties {
        id
        label
      }
      status
      stripe_external_account_id
      travel_email_notification
      travel_email_preferences
      travel_state_names
      travel_states {
        id
      }
      universal_background_checks {
        id
        order_number
        order_status
        order_url
      }
      updated_at
      w2_eligible
      w2_enabled
      w2_onboarding_complete
      welcome_box_sent
    }
  }
`;

export default ProfessionalsShow;
