import React from 'react';
import { EditButton, useRecordContext, TopToolbar } from 'react-admin';
import config from 'config';
import { ENUMS } from 'components/Enum/enums';
import CopyLink from 'components/CopyLink';
import ApprovalButton from './ApprovalButton';
import { CreateTestJobButton } from './CreateTestJobButton';

const JobShowActions = () => {
  const record = useRecordContext();
  const showApprovalButton = record?.status === 'held_for_dispute_review';

  return (
    <TopToolbar>
      {[ENUMS.Job.status.active].includes(record?.status) && (
        <CopyLink textToCopy={`${config.rails_url}/dashboard/jobs/${record.id}`} />
      )}
      <CreateTestJobButton />
      {showApprovalButton ? <ApprovalButton /> : <EditButton data-testid="edit-button" />}
    </TopToolbar>
  );
};

export default JobShowActions;
